import React, { useRef, useEffect } from "react";
import * as Blockly from "blockly";



// blocks created with https://blockly-demo.appspot.com/static/demos/blockfactory/index.html

Blockly.JsonLogic = new Blockly.Generator("JsonLogic");

/**
 * @param {Input} input 
 */
function childInput(input, defaultValue = "null") // null = 'undecided'
{
    var target = input.connection.targetBlock();
    if (!target)
        return defaultValue;
    else
        return Blockly.JsonLogic[target.type](target);
}

Blockly.Blocks['approved'] = {
    init: function ()
    {
        this.appendDummyInput()
            .appendField("Approved");
        this.setOutput(true, "boolean");
        this.setColour("#3b3");
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JsonLogic["approved"] = function (block)
{
    return "true";
};

Blockly.Blocks['unapproved'] = {
    init: function ()
    {
        this.appendDummyInput()
            .appendField("Not approved");
        this.setOutput(true, "boolean");
        this.setColour("#e33");
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JsonLogic["unapproved"] = function (block)
{
    return "false";
};

Blockly.Blocks['warning'] = {
    init: function ()
    {
        this.appendDummyInput()
            .appendField("Warning")
            .appendField(new Blockly.FieldTextInput("Undecided"), "text");
        this.setOutput(true, "string");
        this.setColour("#aa2");
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JsonLogic["warning"] = function (block)
{
    return `"${block.getFieldValue("text")}"`;
};

Blockly.Blocks['if'] = {
    init: function ()
    {
        this.appendValueInput("if")
            .setCheck("boolean")
            .appendField("if");
        this.appendValueInput("then")
            .setCheck(null)
            .appendField("then");
        this.appendValueInput("else")
            .setCheck(null)
            .appendField("else");
        this.setInputsInline(false);
        this.setOutput(true, null);
        this.setColour(235);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JsonLogic["if"] = function (block)
{
    var ifInput = block.getInput("if");
    var thenInput = block.getInput("then");
    var elseInput = block.getInput("else");
    return `{"if":[${childInput(ifInput)},${childInput(thenInput)},${childInput(elseInput)}]}`
};


Blockly.Blocks['compare'] = {
    init: function ()
    {
        this.appendValueInput("left")
            .setCheck(null);
        this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown([["=", "equal"], ["≠", "notequal"], [">", "bigger"], ["<", "smaller"], ["≥", "biggerequal"], ["≤", "smallerequal"]]), "comparison");
        this.appendValueInput("right")
            .setCheck(null);
        this.setInputsInline(true);
        this.setOutput(true, "boolean");
        this.setColour(200);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JsonLogic["compare"] = function (block)
{
    var leftInput = block.getInput("left");
    var rightInput = block.getInput("right");
    var comparison = block.getFieldValue('comparison');
    switch (comparison)
    {
        case "equal":
            return `{"===":[${childInput(leftInput)},${childInput(rightInput)}]}`;
        case "notequal":
            return `{"!==":[${childInput(leftInput)},${childInput(rightInput)}]}`;
        case "bigger":
            return `{">":[${childInput(leftInput)},${childInput(rightInput)}]}`;
        case "smaller":
            return `{"<":[${childInput(leftInput)},${childInput(rightInput)}]}`;
        case "biggerequal":
            return `{">=":[${childInput(leftInput)},${childInput(rightInput)}]}`;
        case "smallerequal":
            return `{"<=":[${childInput(leftInput)},${childInput(rightInput)}]}`;

        default:
            console.warn("unknown comparison", comparison);
            return "null";
    }
};

Blockly.Blocks['not'] = {
    init: function ()
    {
        this.appendValueInput("value")
            .setCheck("boolean")
            .appendField("not");
        this.setOutput(true, "boolean");
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JsonLogic["not"] = function (block)
{
    var input = block.getInput("value");
    return `{"!":${childInput(input)}}`;
};

Blockly.Blocks['math'] = {
    init: function ()
    {
        this.appendValueInput("left")
            .setCheck("number");
        this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown([["+", "add"], ["-", "subtract"], ["*", "multiply"], ["/", "divide"], ["mod", "modulus"]]), "operation");
        this.appendValueInput("right")
            .setCheck("number");
        this.setOutput(true, "number");
        this.setColour(350);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JsonLogic["math"] = function (block)
{
    var leftInput = block.getInput("left");
    var rightInput = block.getInput("right");
    var operation = block.getFieldValue('operation');
    switch (operation)
    {
        case "add":
            return `{"+":[${childInput(leftInput)},${childInput(rightInput)}]}`;
        case "subtract":
            return `{"-":[${childInput(leftInput)},${childInput(rightInput)}]}`;
        case "multiply":
            return `{"*":[${childInput(leftInput)},${childInput(rightInput)}]}`;
        case "divide":
            return `{"/":[${childInput(leftInput)},${childInput(rightInput)}]}`;
        case "mod":
            return `{"%":[${childInput(leftInput)},${childInput(rightInput)}]}`;

        default:
            console.warn("unknown operation", operation);
            return "null";
    }
};

Blockly.Blocks['between'] = {
    init: function ()
    {
        this.appendValueInput("max")
            .setCheck("number");
        this.appendValueInput("value")
            .setCheck("number")
            .appendField("is in range")
            .appendField(new Blockly.FieldDropdown([["include", "include"], ["exclude", "exclude"]]), "minbound");
        this.appendValueInput("min")
            .setCheck("number")
            .appendField("-");
        this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown([["include", "include"], ["exclude", "exclude"]]), "maxbound");
        this.setInputsInline(true);
        this.setOutput(true, "boolean");
        this.setColour(200);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JsonLogic["between"] = function (block)
{
    var valueInput = block.getInput("value");
    var maxInput = block.getInput("max");
    var minInput = block.getInput("min");
    var minInclude = block.getFieldValue('minbound') === "include";
    var maxInclude = block.getFieldValue('maxbound') === "include";

    if (minInclude && maxInclude)
        return `{"<=":[${childInput(minInput)},${childInput(valueInput)},${childInput(maxInput)}]}`;
    else if (!minInclude && !maxInclude)
        return `{"<":[${childInput(minInput)},${childInput(valueInput)},${childInput(maxInput)}]}`;
    else if (minInclude) // && !maxInclude
        return `{"and":[{"<=":[${childInput(minInput)},${childInput(valueInput)}]},{">":[${childInput(maxInput)},${childInput(valueInput)}]}]}`;
    else
        return `{"and":[{"<":[${childInput(minInput)},${childInput(valueInput)}]},{">=":[${childInput(maxInput)},${childInput(valueInput)}]}]}`;
};


Blockly.Blocks['logic'] = {
    init: function ()
    {
        this.appendValueInput("1")
            .setCheck(null)
            .appendField(new Blockly.FieldDropdown([["and", "and"], ["or", "or"]]), "type");
        this.appendValueInput("2")
            .setCheck(null);
        this.setOutput(true, "boolean");
        this.setColour(220);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JsonLogic["logic"] = function (block)
{
    var type = block.getFieldValue("type");
    var field1 = block.getInput("1");
    var field2 = block.getInput("2");

    switch (type)
    {
        case "and":
            return `{"and":[${childInput(field1)},${childInput(field2)}]}`;
        case "or":
            return `{"or":[${childInput(field1)},${childInput(field2)}]}`;
        default:
            console.warn("unknown logic", type);
            return "false";
    }
};


Blockly.Blocks['root'] = {
    init: function ()
    {
        this.appendValueInput("test")
            .setCheck(null)
            .appendField("Test");
        this.setColour(120);
        this.setTooltip("");
        this.setHelpUrl("");
        this.setDeletable(false);
    }
};
/**
 * @param {Block} block 
 */
Blockly.JsonLogic["root"] = function (block)
{
    var testInput = block.getInput("test");
    return childInput(testInput);
};

Blockly.Blocks['var'] = {
    init: function ()
    {
        this.appendDummyInput()
            .appendField("Parameter")
            .appendField(new Blockly.FieldVariable("item"), "param");
        this.setOutput(true, null);
        this.setColour("#557");
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JsonLogic["var"] = function (block)
{
    var paramField = block.workspace.getVariableById(block.getFieldValue("param")).name;
    return `{"var":"${paramField}"}`;
};

Blockly.Blocks['number'] = {
    init: function ()
    {
        this.appendDummyInput()
            .appendField("Number")
            .appendField(new Blockly.FieldNumber(0), "value");
        this.setOutput(true, "number");
        this.setColour(0);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JsonLogic["number"] = function (block)
{
    return block.getFieldValue('value');
};

Blockly.Blocks['text'] = {
    init: function ()
    {
        this.appendDummyInput()
            .appendField("Text")
            .appendField(new Blockly.FieldTextInput("this is some text"), "text");
        this.setOutput(true, null);
        this.setColour(35);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JsonLogic["text"] = function (block)
{
    return `"${block.getFieldValue('text')}"`;
};


export function BlocklyComponent({ onChange, className, xmlList, xmlIndex, globalParamTypes, paramTypes })
{
    const divRef = useRef(null);

    useEffect(() =>
    {
        console.log("Render editor");

        const toolbox = `
        <xml id='toolbox'>
            <category name='Values' colour='325'>
                <block type='approved'></block>
                <block type='unapproved'></block>
                <block type='warning'></block>
                <block type='number'></block>
                <block type='text'></block>
                <block type='var'></block>
            </category >
            <category name='Testing' colour='200'>
                <block type='if'></block>
                <block type='not'></block>
                <block type='logic'></block>
                <block type='compare'></block>
                <block type='between'></block>
                <block type='math'></block>
            </category>
            <category name="Parameters" colour='200' custom="PARAMETERS"></category>
        </xml>`

        Blockly.inject(divRef.current, {
            toolbox: toolbox,
            collapse: true, comments: true, disable: false, scrollbars: true, trashcan: true
        });

        var workspace = Blockly.getMainWorkspace();

        workspace.registerToolboxCategoryCallback("PARAMETERS", () =>
        {
            var xmlList = [];
            xmlList.push(Blockly.Xml.textToDom("<label text='Local'></label>"))
            xmlList.push(Blockly.Xml.textToDom("<button text='New number parameter' callbackKey='newNumberParam'></button>"))
            xmlList.push(Blockly.Xml.textToDom("<button text='New boolean parameter' callbackKey='newBooleanParam'></button>"))
            xmlList.push(Blockly.Xml.textToDom("<button text='New option parameter' callbackKey='newOptionParam'></button>"))
            workspace.getAllVariables().forEach((e) => !e.isGlobal && xmlList.push(Blockly.Xml.textToDom(`<block type="var"><field name="param" variabletype="${e.type}">${e.name}</field></block>`)))
            xmlList.push(Blockly.Xml.textToDom("<label text='Global'></label>"))
            xmlList.push(Blockly.Xml.textToDom("<button text='New global number parameter' callbackKey='newGlobalNumberParam'></button>"))
            xmlList.push(Blockly.Xml.textToDom("<button text='New global boolean parameter' callbackKey='newGlobalBooleanParam'></button>"))
            xmlList.push(Blockly.Xml.textToDom("<button text='New global option parameter' callbackKey='newGlobalOptionParam'></button>"))
            workspace.getAllVariables().forEach((e) => e.isGlobal && xmlList.push(Blockly.Xml.textToDom(`<block type="var"><field name="param" variabletype="${e.type}">${e.name}</field></block>`)))
            return xmlList;
        });

        function newVariable(isGlobal, type = null)
        {
            var name = prompt("Parameter name:");
            if (!name)
                return;

            if (!type)
                type = prompt("Enter possible values for this new parameter, use comma's to seperate each item.\nExample: Option1,Option2");

            var p = workspace.createVariable(name, type);
            p.isGlobal = isGlobal;
            return p;
        }

        // Local
        workspace.registerButtonCallback("newNumberParam", () => newVariable(false, "number"));
        workspace.registerButtonCallback("newBooleanParam", () => newVariable(false, "boolean"));
        workspace.registerButtonCallback("newOptionParam", () => newVariable(false));

        // Global
        workspace.registerButtonCallback("newGlobalNumberParam", () => newVariable(true, "number"));
        workspace.registerButtonCallback("newGlobalBooleanParam", () => newVariable(true, "boolean"));
        workspace.registerButtonCallback("newGlobalOptionParam", () => newVariable(true));

        function changeListener()
        {
            var rootBlock = workspace.getTopBlocks(false).find((e) => e.type === "root");
            if (rootBlock)
            {
                var newGlobalParamTypes = {};
                var newParamTypes = {};

                workspace.getAllVariables().forEach((param) =>
                {
                    if (!("isGlobal" in param))
                    {
                        console.warn("No isGlobal present on param, guessing by name:", param.name);
                        if (param.name in globalParamTypes)
                            param.isGlobal = true;
                        else
                            param.isGlobal = false;
                    }
                    if (param.isGlobal)
                        newGlobalParamTypes[param.name] = param.type;
                    else
                        newParamTypes[param.name] = param.type;
                })

                onChange(workspace, Blockly.JsonLogic[rootBlock.type](rootBlock), newParamTypes, newGlobalParamTypes);
            }
            else
            {
                console.warn("No root block found!");
            }

        }

        workspace.addChangeListener(changeListener);
        return () =>
        {
            workspace.removeChangeListener(changeListener);
            workspace.dispose();
        };
    }, []);

    useEffect(() =>
    {
        /**
         * @type {import("blockly").Workspace}
         */
        var workspace = Blockly.getMainWorkspace();
        // <- TODO: close toolbox

        if (xmlList[xmlIndex])
        {
            console.log("workspace: loading from xml");
            Blockly.Xml.clearWorkspaceAndLoadFromXml(Blockly.Xml.textToDom(xmlList[xmlIndex]), workspace);
        }
        else
        {
            console.log("workspace: new");
            Blockly.Xml.clearWorkspaceAndLoadFromXml(Blockly.Xml.textToDom(
                `<xml><block type="root"></block></xml>`
            ), workspace)
        }

        // Remove serialized xml variables, we will provide them ourselfs
        //workspace.getAllVariables().forEach((e) => workspace.deleteVariableById(e.getId()));

        console.log("loading local vars", paramTypes);
        Object.keys(paramTypes).forEach((key) =>
        {
            var v = workspace.getVariable(key, paramTypes[key]);
            if (!v)
                v = workspace.createVariable(key, paramTypes[key]);
            v.isGlobal = false;
        });

        // Create global variables
        console.log("loading global vars", globalParamTypes);
        Object.keys(globalParamTypes).forEach((key) =>
        {
            var v = workspace.getVariable(key, globalParamTypes[key]);
            if (!v)
                v = workspace.createVariable(key, globalParamTypes[key]);
            v.isGlobal = true;
        });

    }, [xmlIndex])

    return <div className={className} ref={divRef}></div>
}