import React, { useState, useEffect } from "react";
import "./App.scss";
import { Editor, RulesetProject } from "./Editor";
import { download } from "./download";
import QRCode from "react-qr-code";

export function App() {
    const [showOpen, setShowOpen] = useState<boolean>(false);
    const [qrValue, setQrValue] = useState<string>();

    const [project, setProject] = useState<RulesetProject>(() => {
        var loadedProject = new RulesetProject();
        try {
            const saved = localStorage.getItem("diginspect-project");
            if (saved) {
                loadedProject = JSON.parse(saved);
                console.log("Loaded project from localStorage");
            }
        } catch (ex) {
            console.warn("Could not load from localStorage", ex);
        }
        return loadedProject;
    });

    useEffect(() => {
        function keydown(ev: KeyboardEvent) {
            if (ev.key === "s" && ev.ctrlKey) {
                // Override ctrl+s
                ev.preventDefault();
                exportProject();
            }
        }

        window.addEventListener("keydown", keydown);
        return () => window.removeEventListener("keydown", keydown);
    }, []);

    useEffect(() => {
        try {
            localStorage.setItem("diginspect-project", JSON.stringify(project));
        } catch (ex) {
            console.warn("Cannot set localstorage", ex);
        }
    }, [project]);

    function exportProject() {
        setProject((project) => {
            download(JSON.stringify(project), project.ruleset.name + ".json", "appliction/json");
            return project;
        });
    }

    function importProject(ev: React.ChangeEvent<HTMLInputElement>) {
        console.log("Importing file");

        const files = ev.target.files;
        if (!files || files.length <= 0) return;

        const reader = new FileReader();
        reader.addEventListener("load", (f) => {
            const text = f.target!.result as string;
            const obj = JSON.parse(text);

            setProject(obj);
        });
        reader.readAsBinaryString(files[0]);
    }

    function createQRCode() {
        fetch("https://file.io/", {
            method: "POST",
            body: "text=" + encodeURIComponent(JSON.stringify(project.ruleset)),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.success) {
                    setQrValue(res.link);
                } else {
                    console.warn("Could not create qr code, returned false success value", res);
                }
            })
            .catch((err) => {
                console.warn("Could not create qr code:", err);
            });
    }

    return (
        <div className="app">
            <div className="app__nav">
                <span className="app__nav__title">Diginspect</span>
                <button className="button button--margin" onClick={() => setProject(new RulesetProject())}>
                    New
                </button>
                <button className="button button--margin" onClick={() => exportProject()}>
                    Save
                </button>
                <button className="button button--margin" onClick={() => setShowOpen(true)}>
                    Open
                </button>
                <button className="button button--margin" onClick={createQRCode}>
                    Create QR code
                </button>
            </div>
            {showOpen && (
                <div className="overlay overlay--center-content">
                    <input
                        className="button button--margin"
                        type="file"
                        onChange={(ev) => {
                            importProject(ev);
                            setShowOpen(false);
                        }}
                    />
                    <button className="button button--margin" onClick={() => setShowOpen(false)}>
                        Cancel
                    </button>
                </div>
            )}
            {qrValue && (
                <div className="overlay overlay--center-content" onClick={() => setQrValue(undefined)}>
                    <p className="mb">This is a single use qr code for the Diginspect app.</p>
                    <div style={{ padding: "1.2em", background: "#fff" }}>
                        <QRCode value={qrValue} />
                    </div>
                    <p className="mt">(click anywhere to return to the editor)</p>
                </div>
            )}
            <Editor project={project} onProjectChange={(project) => setProject(project)} />
        </div>
    );
}
