import React, { SetStateAction } from "react";
import { BlocklyComponent } from "./BlocklyComponent";
import { InspectionRuleSet, InspectionTest } from "diginspect-api";
import "./Editor.scss";
import Blockly from "blockly";
import { JsonLogicParamTypes } from "diginspect-api";

type InspectionTestItemProps = {
    className?: string;
    test: InspectionTest;
    onChange: (val: InspectionTest) => void;
    onRemove: () => void;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    allowEdit: boolean;
};

export function InspectionTestItem({
    className,
    test,
    onChange,
    onClick,
    onRemove,
    allowEdit,
}: InspectionTestItemProps) {
    return (
        <div className={"inspection-test " + (className ?? "")} onClick={onClick}>
            <div className="inspection-test__header">
                {test.referenceIndex && (
                    <span className="inspection-test__header__ref-index">{test.referenceIndex}</span>
                )}
                <span className="inspection-test__header__description">{test.description ?? "(no description)"}</span>
                {test.descriptionReference && (
                    <span className="inspection-test__header__description-ref">{test.descriptionReference}</span>
                )}
                {test.descriptionReferenceIndex && (
                    <span className="inspection-test__header__description-ref-index">
                        {test.descriptionReferenceIndex}
                    </span>
                )}
            </div>
            {allowEdit && (
                <div className="inspection-test__edit">
                    <input
                        className="inspection-test__edit__input"
                        type="text"
                        placeholder="category"
                        value={test.category ?? ""}
                        onChange={(ev) => onChange({ ...test, category: ev.target.value ?? undefined })}
                    />
                    <input
                        className="inspection-test__edit__input"
                        type="text"
                        placeholder="reference index"
                        value={test.referenceIndex ?? ""}
                        onChange={(ev) => onChange({ ...test, referenceIndex: ev.target.value ?? undefined })}
                    />
                    <input
                        className="inspection-test__edit__input"
                        type="text"
                        placeholder="description"
                        value={test.description ?? ""}
                        onChange={(ev) => onChange({ ...test, description: ev.target.value ?? undefined })}
                    />
                    <input
                        className="inspection-test__edit__input"
                        type="text"
                        placeholder="description reference"
                        value={test.descriptionReference ?? ""}
                        onChange={(ev) => onChange({ ...test, descriptionReference: ev.target.value ?? undefined })}
                    />
                    <input
                        className="inspection-test__edit__input"
                        type="text"
                        placeholder="description reference index"
                        value={test.descriptionReferenceIndex ?? ""}
                        onChange={(ev) =>
                            onChange({ ...test, descriptionReferenceIndex: ev.target.value ?? undefined })
                        }
                    />
                    <button className="button button--block button--margin" onClick={onRemove}>
                        Remove
                    </button>
                </div>
            )}
        </div>
    );
}

type InspectionTestListProps = {
    ruleset: InspectionRuleSet;
    selectedTestId?: string;
    onTestChange: (val: InspectionTest) => void;
    onTestRemove: (val: InspectionTest) => void;
    onTestSelect: (val: InspectionTest) => void;
};

export function InspectionTestList({
    ruleset,
    selectedTestId,
    onTestChange,
    onTestRemove,
    onTestSelect,
}: InspectionTestListProps) {
    return (
        <ul className="inspection-test-list">
            {ruleset.tests
                .sort((a, b) => a.id.localeCompare(b.id) ?? 0)
                .map((t) => (
                    <InspectionTestItem
                        key={t.id}
                        test={t}
                        className={selectedTestId === t.id ? "inspection-test--selected" : ""}
                        onRemove={() => onTestRemove(t)}
                        onChange={onTestChange}
                        allowEdit={t.id === selectedTestId}
                        onClick={() => {
                            if (selectedTestId !== t.id) {
                                onTestSelect(t);
                            }
                        }}
                    />
                ))}
        </ul>
    );
}

export class RulesetProject {
    testXmls: Record<string, string>;
    ruleset: InspectionRuleSet;
    editingTestId?: string;

    constructor() {
        this.testXmls = {};
        this.ruleset = new InspectionRuleSet("New ruleset", {});
    }
}

type EditorProps = {
    project: RulesetProject;
    onProjectChange: React.Dispatch<SetStateAction<RulesetProject>>;
};

export function Editor({ project, onProjectChange: setProject }: EditorProps) {
    const editingTest = project.ruleset.tests.find((e) => e.id === project.editingTestId);

    function onTestRemove(test: InspectionTest) {
        if (!window.confirm(`Are you sure you want to remove test '${test.description}'`)) return;
        const newTests = project.ruleset.tests.filter((e) => e.id !== test.id);
        const newProject = { ...project };
        newProject.ruleset.tests = newTests;
        newProject.editingTestId = newTests.length > 0 ? newTests[0].id : undefined;
        setProject(newProject);
    }

    function onTestChange(test: InspectionTest) {
        setProject({
            ...project,
            ruleset: {
                ...project.ruleset,
                tests: [...project.ruleset.tests.filter((e) => e.id !== test.id), test],
            },
        });
    }

    function onTestSelect(test: InspectionTest) {
        setProject({
            ...project,
            editingTestId: test.id,
        });
    }

    function onBlocklyChange(
        workspace: Blockly.Workspace,
        jsonLogic: string,
        newParamTypes: JsonLogicParamTypes,
        newGlobalParamTypes: JsonLogicParamTypes
    ) {
        var newXml = Blockly.Xml.workspaceToDom(workspace);
        var textXml = Blockly.Xml.domToText(newXml);

        // Using callbacks due to state not yet mutated on event call
        setProject((project) => {
            const newProject = { ...project };

            if (newProject.editingTestId === undefined) return newProject;

            newProject.ruleset.globalParamTypes = newGlobalParamTypes;

            var editingTest = newProject.ruleset.tests.find((e) => e.id === newProject.editingTestId)!;
            editingTest.jsonLogicParamTypes = newParamTypes;
            editingTest.jsonLogic = jsonLogic;

            newProject.testXmls[newProject.editingTestId] = textXml;

            return newProject;
        });
    }

    function newTest() {
        setProject({
            ...project,
            ruleset: {
                ...project.ruleset,
                tests: [...project.ruleset.tests, new InspectionTest("Not implemented", {})],
            },
        });
    }

    return (
        <div className="editor">
            <div className="editor__sidebar">
                <h2 className="editor__sidebar__title">Ruleset '{project.ruleset.name}'</h2>
                <div className="ruleset-info">
                    <span className="ruleset-info__label">Id</span>
                    <small>{project.ruleset.id}</small>
                    <span className="ruleset-info__label">Name</span>
                    <input
                        className="ruleset-info__data"
                        type="text"
                        placeholder="name"
                        value={project.ruleset.name}
                        onChange={(val) =>
                            setProject({ ...project, ruleset: { ...project.ruleset, name: val.target.value } })
                        }
                    />
                    <span className="ruleset-info__label">Reference</span>
                    <input
                        className="ruleset-info__data"
                        type="text"
                        placeholder="ISO xxxx"
                        value={project.ruleset.reference ?? ""}
                        onChange={(val) =>
                            setProject({
                                ...project,
                                ruleset: { ...project.ruleset, reference: val.target.value ?? undefined },
                            })
                        }
                    />
                    <span className="ruleset-info__label">Description</span>
                    <textarea
                        className="ruleset-info__data"
                        placeholder="For testing against the standard ..."
                        value={project.ruleset.description ?? ""}
                        onChange={(ev) =>
                            setProject({
                                ...project,
                                ruleset: { ...project.ruleset, description: ev.target.value ?? undefined },
                            })
                        }
                    ></textarea>
                    <span className="ruleset-info__label">Tests</span>
                    <button className="button button--block button--margin" onClick={newTest}>
                        New test
                    </button>
                    <InspectionTestList
                        selectedTestId={editingTest?.id}
                        ruleset={project.ruleset}
                        onTestChange={onTestChange}
                        onTestRemove={onTestRemove}
                        onTestSelect={onTestSelect}
                    />
                </div>
            </div>
            {editingTest ? (
                <BlocklyComponent
                    xmlList={project.testXmls}
                    xmlIndex={editingTest.id}
                    className="editor__code"
                    onChange={onBlocklyChange}
                    paramTypes={editingTest.jsonLogicParamTypes}
                    globalParamTypes={project.ruleset.globalParamTypes}
                />
            ) : (
                <div className="editor__nothing">Select or create a test to edit it here</div>
            )}
        </div>
    );
}
